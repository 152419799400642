export const revisionItems = [
    {
        name: 'post_meta_title',
        component: 'input',
        previewComponent: 'preview-text-field',
        preview_field: 'post_meta_title',
        hideComponent: false,
        label: 'Meta Title tag',
        placeholder: 'Meta Title tag',
        legend: '<Meta Title> tag'
    },
    {
        name: 'post_title_secondary',
        component: 'input',
        previewComponent: 'preview-text-field',
        preview_field: 'post_title_secondary',
        hideComponent: false,
        label: 'Title Secondary',
        placeholder: 'Title Secondary',
        legend: 'Title Secondary'
    },
    {
        name: 'post_meta_description',
        component: 'textarea',
        previewComponent: 'preview-description-field',
        preview_field: 'post_meta_description',
        hideComponent: false,
        label: '<Meta Description> tag',
        placeholder: 'Meta Description tag',
        legend: '<Meta Description> tag'
    },
    {
        name: 'post_title_primary',
        component: 'input',
        previewComponent: 'preview-text-field',
        preview_field: 'post_title_primary',
        hideComponent: false,
        label: 'H1 Title',
        placeholder: 'H1 Title',
        legend: 'H1 Title'
    },
    {
        name: 'post_keyword_singular',
        component: 'input',
        previewComponent: 'preview-text-field',
        preview_field: 'post_keyword_singular',
        hideComponent: false,
        label: 'Keywords singular',
        placeholder: 'Keywords singular',
        legend: 'Keywords singular'
    },
    {
        name: 'post_keyword_plural',
        component: 'input',
        previewComponent: 'preview-text-field',
        preview_field: 'post_keyword_plural',
        hideComponent: false,
        label: 'Keywords plural',
        placeholder: 'Keywords plural',
        legend: 'Keywords plural'
    },
    {
        name: 'post_button_keyword',
        component: 'input',
        previewComponent: 'preview-text-field',
        preview_field: 'post_button_keyword',
        hideComponent: false,
        label: 'Button Keywords',
        placeholder: 'Button Keywords',
        legend: 'Button Keywords'
    },
    {
        name: 'post_content_primary',
        component: 'textarea',
        previewComponent: 'preview-text-field',
        preview_field: 'post_content_primary',
        hideComponent: false,
        label: 'Description',
        placeholder: 'Description',
        legend: 'Description'
    },
    {
        name: 'post_content_secondary',
        component: 'content',
        previewComponent: 'preview-description-field',
        preview_field: 'post_content_secondary',
        hideComponent: false,
        label: 'Content',
        placeholder: 'Content',
        legend: 'Content'
    }
]
