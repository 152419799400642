<template>
    <custom-revisions
        :fields="fields"
        :item="landing"
        :posts="landings"
        :selected-items="selectedItems"
        :loading="loading || !landing"
        :return-back-route-name="'posts-list-landing'"
        :reviews-type="'landing_revisions'"
        @deleteRevision="deleteRevision($event)"
        @activateRevision="activateRevision($event)"
        @addSelectedItem="addSelectedItem($event)"
        @getRevisions="getRevisions($event)"
    />
</template>

<script>
import newRevisionsMixins from '@/mixins/revisions/newRevisionsMixins';

import { revisionItems } from '@/helpers/constants/custom-editor/landing/create-new-landing-revision-items'

import {
    ACTIONS_GET_LANDING_REVISIONS,
    ACTION_ACTIVATE_LANDING_REVISION,
    ACTION_DELETE_LANDING_REVISION
} from '@/store/modules/content/content-revisions/action-types'
import { mapContentRevisionsFields } from '@/store/modules/content/content-revisions'
import { mapState, createNamespacedHelpers } from 'vuex';

const {
    mapActions: mapContentRevisionsActions
} = createNamespacedHelpers('contentRevisions');

export default {
    name: 'IndexVue',
    mixins: [
        newRevisionsMixins
    ],
    computed: {
        ...mapContentRevisionsFields([
            'landing',
            'landing.revisions',
            'landing.post',
            'loading'
        ]),
        ...mapState('contentList', [
            'landings'
        ]),
        fields() {
            return revisionItems
        }
    },
    methods: {
        ...mapContentRevisionsActions([
            ACTIONS_GET_LANDING_REVISIONS,
            ACTION_ACTIVATE_LANDING_REVISION,
            ACTION_DELETE_LANDING_REVISION
        ]),
        getRevisions(event) {
            this[ACTIONS_GET_LANDING_REVISIONS](event)
            this.selectedItems = []
        },
        activateRevision(event) {
            this[ACTION_ACTIVATE_LANDING_REVISION]({ post_id: this.post.id, revision_id: event.id })
                .then(() => {
                    this.$bus.$emit('showSnackBar', 'Your active revision was changed', 'success')
                })
        },
        deleteRevision(event) {
            this[ACTION_DELETE_LANDING_REVISION]({ post_id: this.post.id, revision_id: event.id })
                .then(() => {
                    const index = this.selectedItems.findIndex((i) => i === event.id)
                    this.selectedItems.splice(index, 1)
                    this.$bus.$emit('showSnackBar', 'Success revision delete', 'error')
                })
        }
    }
}
</script>

<style scoped>

</style>
